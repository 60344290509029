@import '../../../../styles/variables.scss';

.list {
  margin-top: 70px;
  padding-bottom: 10px;
  width: 100%;
  z-index: 200;
  position: absolute;
  overflow: auto;
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  align-items: center;
  &::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}

.box {
	@include transition;
	background-color: $white;
	box-shadow: 0px 10px 28px rgb(224, 224, 224);
  border-radius: $radius;
  min-width: 50px;
  width: 90%;
  &:not(:first-child) {
    margin-top: 20px;
  };
}

.main-row {
  width: 100%;
  display: flex;
  padding: 10px 0px 10px 20px;
  z-index: 100;
  button {
    @include btn;
    color: rgba($dark , 0.5);
  }
  &.labels {
    position: absolute;
    width: 88%;
    padding-top: 40px;
    padding-left: 8px;
    .icon {
      width: 24px;
      height: 24px;
      transform: translate(2px , 1px )
    }
  }
  .main-col {
    width: calc((100% - 40% - 50px) / 4) ;
    display: flex;
    align-items: center;
    p {
      margin: 0px;
      font-size: 14px;
    }
    &.drugs {
      width: 20%;
    }
    &.structure {
      width: 20%;
      word-break: keep-all;
      padding-right: 10px;
      .name {
        text-transform: uppercase;
      }
    }
  }
  .comment-toggle {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      @include transition;
      transform: scale(0.9);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }

  }
  .view-toggle {
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgba($dark , 0.2 );
    svg {
      @include transition;
      transform: scale(0.9);
    }
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }
}