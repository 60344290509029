@import '../../../styles/variables.scss';

.logo {
  background-color: $primary;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 20px -4px hsla(0,0%,53.3%,.57);
}