@import '../../../../../styles/variables.scss';

.expiration {
  overflow: hidden;
  @include transition;
  width: 100%;
  button {
    transform: scale(1.4);
  }
  .list-lots {
    padding: 20px 20px;
    .lot {
      display: flex;
      height: 40px;
      justify-content: space-between;
      align-items: center;
      &.labels {
        color: rgba($dark , 0.5);
      }
      &.border {
        border-top: 1px solid rgba($dark , 0.2 );
      }
      p {
        font-size: 14px;
        margin: 0px;
        &.right {
          text-align: right;
        }
        &.number {
          width: calc(60% / 5 );
        }
        &.order {
          width: 30%;
        }
      }
    }
  }
}