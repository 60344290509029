@import './../../styles/variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  .content {
    @include animation-mixin-y(slideInLogin, 50px, 0);
    animation-name: slideInLogin;
    animation-duration: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo{
      display: flex;
      align-items: center;
    }
    .message {
      margin: 20px;
      color: #999EB0;
      display: flex;
      align-items: center;
      flex-direction: column;
      p {
        margin: 10px;
        text-align: center;
        max-width: 220px;
      }
      // @media only screen and (min-width: $xs-screen) {
      //   flex-direction: row;
      //   justify-content: center;
      //   p {
      //     max-width: 100%;
      //   }
      // }

    }
  }
}