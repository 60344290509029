@import '../../../../styles/index.scss';

.upload {
  display: flex;
  height: 100%;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  .col {
    width: 60%;
    h1 {
      margin-top: 0px;
      text-transform: none;
      font-size: 38px;
      font-family: "Ubuntu-Bold";
    }
    p {
      max-width: 450px;
      line-height: 25px;
      font-size: 14px;
    }
    .upload-row {
      margin-top: 80px;
      width: 100%;
      display: flex;
      justify-content: space-between;    
      .info {
        width: 45%;
        h2 {
          font-family: "Ubuntu-Bold";
          font-size: 18px;
          margin: 0px;
        }
        p {
          margin: 10px 0px;
          &.text-error {
            min-height: 25px;
          }
        }
        label {
          width: 100%;
          display: block;
          cursor: pointer;
          text-transform: none;
          display: flex;
          align-items: center;
          padding: 10px 20px;
          svg {
            margin-right: 10px;
          }
        }
        #upload-stock , #upload-expiration {
            opacity: 0;
            position: absolute;
            z-index: -1;
        }
      }
    }
    &-image {
      width: 30%;
      img {
        transform: translateX(-35%)
      }
    }
  }
}