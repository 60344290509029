@import '../../../styles/variables.scss';

.pharmacy {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.product-list {
  margin-top: 50px;
}

.product-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 18px 0px ;
  z-index: 100;
  button {
    @include btn;
    color: rgba($dark , 0.5);
  }
  &.labels {
    width: calc(100% - 50px);
    padding: 0px;
    .icon {
      width: 24px;
      height: 24px;
      transform: translate(2px , 1px )
    }
    p {
      color: rgba($dark , 0.5);
    }
  }
  .comment-toggle {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      padding: 0px;
      img {
        @include transition;
        transform: scale(0.9);
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .main-col {
    width: 25% ;
    display: flex;
    align-items: center;
    font-size: 14px;
    p {
      margin: 0px;
    }
    .name {
      padding-left: 20px;
    }
    &.name {
      padding-left: 20px;
    }
    &.stock {
      width: calc(80% / 6);
      p {
        width: 100%;
        text-align: center;
      }
    }
    &.history {
      width: calc(80% / 12);
      p {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.expiration {
  width: 100%;
  button {
    margin-right: 20px;
    svg {
      transform: scale(2.3);
    }
  }
  .list-lots {
    @include transition;
    padding: 0px 20px;
    .lot {
      display: flex;
      height: 40px;
      align-items: center;
      animation: enter-lot 0.2s ;
      animation-fill-mode: forwards;
      @include animation-mixin-y(enter-lot, -38px, 0px);
      &.border {
        border-top: 1px solid rgba($dark , 0.2 );
      }
      p {
        width: 25%;
        font-size: 14px;
        margin: 0px;
      }
    }
  }
}

.comment {
  height: 0px;
  @include transition;
	background-color: $white;
	box-shadow: 0px 10px 28px rgb(224, 224, 224);
  border-radius: 0px 0px $radius $radius;
  width: calc(100% - 50px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  opacity: 0;
  border-top: 1px solid rgba($dark , 0 );
  font-size: 14px;
  &.isOpen {
    border-top: 1px solid rgba($dark , 0.2 );
    height: 50px;
    opacity: 1;
  }
}