@import '../../../styles/variables.scss';

.filters {
  transition: all 0.1s ease-in-out;
  position: absolute;
  background-color: $primary;
  top: 68px;
  border-radius: $radius;
  border: 1px solid $white;
  width: 48px;
  z-index: 300;
  display: flex;
  color: $white;
  font-size: 14px;
  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
  &.isOpen {
    width: calc((181px * 4) + 43px);
  }
  // &.mutations {
  //   width: calc((201px * 5) + 43px);
  // }
  .btn-filters {
    width: 45px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filter {
    @include transition;
    width: 0px;
    opacity: 0;
    &.isOpen {
      width: 190px;
      opacity: 1;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.border {
      border-right: 1px solid $white;
    }
    button {
      border-radius: 30px;
      margin-right: 5px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba($white, 0.2)
      }
      &.btn-radio {
        width: 100%;
        opacity: 0.5;
        &.isActive {
          opacity: 1;
        }
        &:hover {
          background-color: rgba($white, 0);
          opacity: 1;
        }
      }
    }
    p {
      padding-left: 10px;
      color: $white;
      margin: 0px;
    }
    input {
      font-family: "Ubuntu-Regular";
      background-color: transparent;
      outline: none;
      border: 0px;
      max-width: 150px;
      color: #FFFFFF;
      font-size: 14px;
      &::placeholder {
        color: #FFFFFF;

      }
    }
  }
}