@import '../../../../../../styles/variables.scss';

.view {
  overflow: hidden;
  @include transition;
  border-top: 1px solid rgba($dark , 0 );
  &.isActive {
    border-top: 1px solid rgba($dark , 0.2 );
  }
  .list-row {
    display: flex;
    padding: 10px 0px;
    margin: 5px 20px;
    &.border {
      border-bottom: 1px solid rgba($dark , 0.2 );
    }
    &.labels {
      color: rgba($dark , 0.5);
      top: 20px;
    }
    p {
      margin:0px;
      width: calc(100% / 4);
      font-size: 14px;
      &.month {
        width: 10%;
        text-align: center;
      }
      &.stock {
        width: 20%;
        text-align: center;
      }
      .lots {
        font-weight: normal;
      }
    }
  }
}