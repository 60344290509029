@import './variables.scss';

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  color: $dark;
  font-family: "Ubuntu-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
}

* {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

//MAIN-CONTAINERS

.app-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.page-container {
  margin-top: 60px;
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 0;
  display: flex;
  justify-content: center;
  @media only screen and (min-width: $md-screen) {
    margin-top: 80px;
    height: calc(100vh - 80px);
  }
}

.content {
  position: relative;
  width: 90%;
  z-index: -1;
  @media only screen and (min-width: $md-screen) {
    width: 96%;
  }
  @media only screen and (min-width: $lg-screen) {
    width: 90%;
  }
}

//MAIN_CLASSES

.uppercase {
  text-transform: uppercase;
}

.semi-bold {
  font-family: "AnyBody-SemiBold";
}

.bold {
  font-weight: bold;
}

.regular {
  font-family: "Anybody-Regular";
}

label {
  font-family: "Ubuntu-Regular";
}

button {
  @include btn;
}

.text-primary {
  color: $primary
} 

.m-md {
  margin: $margin-md
} 

.text-center {
  text-align: center;
}

.text-error {
  color: $error;
}

.grey-label {
  font-family: "AnyBody-SemiBold";
  text-transform: uppercase;
  color: rgba($dark , 0.25 );
}

h1 {
  text-transform: uppercase;
  font-size: 21px;
  font-family: "Anybody-Bold";
}

.list {
  margin-top: 70px;
  padding-bottom: 10px;
  width: 100%;
  z-index: 200;
  position: absolute;
  overflow: auto;
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  align-items: center;
  &::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}

.box {
	@include transition;
	background-color: $white;
	box-shadow: 0px 10px 28px rgb(224, 224, 224);
  border-radius: $radius;
  min-width: 50px;
  &:not(:first-child) {
    margin-top: 20px;
  };
}


.btn {
  @include transition;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: $radius;
  font-size: 14px;
  text-transform: uppercase;
  &.primary {
    color: $white;
    text-decoration: none;
    background-color: $primary;
    box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.25);
    &:hover {
      background-color: $primary;
      transform: scale(1.03)
    }
  }
}

.loader {
  display: flex;
  svg {
    animation:spin 2s linear infinite;
  }
}

@keyframes spin {
  100% { transform:rotate(360deg)} 
}






