@import '../../../styles/index.scss';

.toaster {
  @include transition;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 200px;
  z-index: 3000;
  transform: translateX(-230px);
  box-shadow: $shadow;
  background-color: $primary;
  border-radius: $radius;
  color: $white;
  p {
    margin: 0px;
    text-align: left;
    font-size: 14px
  }
  &.success {
    background-color: $primary;
    color: $white;
  }
  &.error {
    background-color: $error;
    color: $white;
  }
  &.isActive {
    transform: translateX(0px);
    padding: 10px;
  }
}