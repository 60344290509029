@import '../../../../../../styles/variables.scss';

.comments {
  @include transition;
  background-color: $white;
	box-shadow: 10px 10px 28px rgb(224, 224, 224);
  border-radius: 0px 0px $radius $radius;
  width: calc(90% - 52px);
  margin-left: -52px;
  .comments-list {
    border-top: 1px solid rgba($dark , 0.2 );
    width: 100%;
    height: 100%;
    .comment {
      opacity: 1;
      animation: enter 0.1s ;
      height: 62px;
      width: 100%;
      animation-fill-mode: forwards;
      @include animation-mixin-y(enter, -38px, 0px);
      display: flex;
      justify-content: space-between;
      &.border {
        border-bottom: 1px solid rgba($dark , 0.2 );
      }
      .text {
        display: flex;
      }
      p {
        margin: 20px;
        font-size: 14px;
        max-width: 800px;
        &.drug {
          min-width: 120px;
          margin: 20px 20px 20px 0px;
          color: $primary;
        }
      } 
    }
  }
}