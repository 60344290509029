@import '../../../styles/variables.scss';

.container {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .link {
    color: $dark;
    margin: 5px 30px;
    text-align: center;
    font-size: 12px;
    &:hover {
      color: $primary;
    }
  }
}